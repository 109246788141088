import { ArrowDownIcon } from "lucide-react";
import { cn } from "../lib/utils";

const Hero = () => {
  return (
    <section
      id="home"
      className="relative min-h-screen flex flex-col items-center justify-center text-center px-6 overflow-hidden bg-white"
    >
      {/* Retro decorative elements */}
      <div className="absolute top-0 left-0 w-full h-12 bg-black"></div>
      <div className="absolute bottom-0 left-0 w-full h-12 bg-black"></div>
      
      {/* Retro geometric shapes */}
      <div
        className="absolute top-[20%] left-[15%] w-32 h-32 rounded-full border-4 border-black/40 opacity-50"
        style={{ transform: "rotate(-15deg)" }}
      ></div>
      <div
        className="absolute bottom-[25%] right-[10%] w-40 h-40 border-4 border-black/40 opacity-60"
        style={{ transform: "rotate(30deg)" }}
      ></div>
      <div
        className="absolute top-[40%] right-[15%] w-20 h-20 bg-black/20 rotate-45"
      ></div>

      <div className="relative z-10 max-w-3xl mx-auto flex flex-col items-center animate-fade-in">
        <div className="inline-block rounded-lg bg-muted px-6 py-2 mb-6 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,0.8)]">
          <span className="text-sm font-mono font-medium text-black tracking-wide">
            Hello, I'm Alan K Mathew
          </span>
        </div>

        <h1 className="text-4xl md:text-5xl lg:text-7xl font-display font-bold tracking-tight mb-6 text-black">
          Building{" "}
          <span className="relative inline-block px-2 text-white">
            <span className="absolute inset-0 bg-primary -skew-y-2"></span>
            <span className="relative">secure</span>
          </span>{" "}
          and{" "}
          <span className="relative inline-block px-2 text-white">
            <span className="absolute inset-0 bg-secondary skew-y-2"></span>
            <span className="relative">intelligent</span>
          </span>{" "}
          systems
        </h1>

        <p className="text-lg md:text-xl text-black mb-10 max-w-2xl font-mono">
        I'm a software engineer specializing in usability and security
        with a passion for embedded systems, and AI-driven applications.
        </p>
        
        <div className="flex flex-col sm:flex-row gap-4">
          <a href="#projects" className="relative inline-block px-6 py-3 font-mono font-bold uppercase text-white">
            <span className="absolute inset-0 bg-primary -skew-y-2"></span>
            <span className="relative">VIEW MY WORK</span>
          </a>
          <a href="#contact" className="relative inline-block px-6 py-3 font-mono font-bold uppercase text-white">
            <span className="absolute inset-0 bg-secondary skew-y-2"></span>
            <span className="relative">GET IN TOUCH</span>
          </a>
        </div>
      </div>

      <a
        href="#about"
        className="absolute bottom-16 left-1/2 -translate-x-1/2 w-10 h-10 flex items-center justify-center text-black hover:text-primary transition-colors duration-300"
        aria-label="Scroll down"
        onClick={(e) => {
          e.preventDefault();
          document.querySelector("#about")?.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        <ArrowDownIcon
          className={cn(
            "w-6 h-6 animate-bounce"
          )}
        />
      </a>
    </section>
  );
};

export default Hero;