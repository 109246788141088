import React from 'react';
import { Code, Globe, Wrench } from 'lucide-react';
import '../styles/Skills.css';

const SkillCategory = ({ title, skills, icon: Icon }) => (
  <div className="skill-card">
    <div className="skill-header">
      <Icon className="skill-icon" />
      <h3 className="skill-title">{title}</h3>
    </div>
    {Array.isArray(skills[0]) ? (
      <ul className="skill-list">
        {skills.map((skillGroup, i) => (
          <li key={i} className="skill-item">
            <span className="skill-group">{skillGroup[0]}</span>
            <div className="skill-details">
              {skillGroup[1].split(', ').map((skill, j) => (
                <span key={j} className="skill-tag">{skill}</span>
              ))}
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div className="language-skills">
        {skills.map((skill, i) => (
          <span 
            key={i} 
            className="language-tag"
          >
            {skill}
          </span>
        ))}
      </div>
    )}
  </div>
);

const Skills = () => {
  const skillCategories = [
    {
      title: "Technical Skills",
      icon: Code,
      skills: [
        ["Programming Languages", "Python, C++, JavaScript, Objective-C, Kotlin, Bash Scripting"],
        ["Databases", "MongoDB, Neo4j, Redis, MySQL"],
        ["Software Development & Frameworks", "React, React Native, Express.js, Enterprise Architect, Figma"],
        ["Embedded & Systems Programming", "Raspberry Pi, Arduino, NFC Stack, Bluetooth (BLE/Classic) Stack, Trusted Execution Environment (TEE)"],
        ["Cybersecurity & Cryptography", "X.509 Certificates, mTLS, OpenSSL, PKI, Secure Storage, OSINT, Wireshark, Burp Suite"],
        ["DevOps & Cloud", "CI/CD Pipelines, Docker, Kubernetes, GCP, Digital Ocean, Azure DevOps, GitHub Actions"],
        ["AI & Machine Learning", "Large Language Models (LLM) Implementation, Prompt Engineering, RAG"]
      ]
    },
    {
      title: "Specialised Tools & Technologies",
      icon: Wrench,
      skills: [
        ["Version Control & ALM", "Git, Polarion, Apache Velocity, ASCIIDOC"],
        ["System & Software Architecture", "Software Documentation, Requirements Engineering, UI/UX Design"],
        ["Task Automation", "Python Scripting, Velocity, Process Optimisation"],
        ["Networking & Security", "Network Configuration, Cybersecurity Protocols, Bluetooth, NFC"]
      ]
    },
    {
      title: "Languages",
      icon: Globe,
      skills: [
        "English (Full Professional)",
        "German (Elementary)",
        "Hindi (Professional)",
        "Malayalam (Native)",
        "Tamil (Professional)",
        "Kannada (Limited)"
      ]
    }
  ];

  return (
    <section id="skills" className="skills-section">
      <div className="container-width">
        <h2 className="skills-section-title section-title">Professional Competencies</h2>
        <p className="skills-intro">A comprehensive overview of my technical expertise and professional capabilities</p>
        <div className="skills-container">
          {skillCategories.map((category, index) => (
            <SkillCategory key={index} {...category} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;